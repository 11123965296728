import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Http, Headers, RequestOptions } from '@angular/http';
import { constants } from '../../config/constants';
import { apis } from '../../config/apis';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Options } from 'selenium-webdriver/opera';

@Injectable({
  providedIn: 'root'
})


export class CrudService {

  loggedUser = false;

  constructor(private http: HttpClient) {

  }



  public post(api, postdata) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        // 'Authorization': 'my-auth-token'
      })
    };
    return this.http.post(environment.baseUrl + api, postdata, httpOptions);
  }

  public get(api) {
    return this.http.get(environment.baseUrl + api);
  }

  public update(api, data) {
    return this.http.post(environment.baseUrl + api, data);
  }

  public delete(api, data) {
    return this.http.delete(environment.baseUrl + api, data);
  }

  public postJson(api, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        // 'Authorization': 'my-auth-token'
      })
    };
    return this.http.post("https://carter-cors.herokuapp.com/"+environment.baseUrl + api, data, httpOptions);
  }

  public postFormdata(api, postdata) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/html; charset=UTF-8',
        // 'Authorization': 'my-auth-token'
      })
    };
    return this.http.post(environment.baseUrl + api, postdata, httpOptions);
  }

  public postFormdataUploadImage(api, data) {

    debugger
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'multipart/form-data',
    //   })
    // };
    return this.http.post(environment.baseUrl + api, data);
  }


  public postOla(api, data, token) {
    console.log("here");
    console.log("token", token);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: token
      })
    };
    return this.http.post("https://carter-cors.herokuapp.com/" + environment.baseUrl + api, data, httpOptions);
  }

  public postOlaBooking(api, data, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: token
      })
    };
    return this.http.post(
      "https://carter-cors.herokuapp.com/" + environment.baseUrl + api,
      data,
      httpOptions
    );
  }




  //////////intertransfer
  public postWithDytnamicToken(api, data, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: token
      })
    };
    return this.http.post(
      "https://carter-cors.herokuapp.com/" + environment.baseUrl + api,
      data,
      httpOptions
    );
  }
  public postWithCorporateToken(api, data, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token:token
      })
    };
    return this.http.post(
      "https://carter-cors.herokuapp.com/" + environment.baseUrl + api,
      data,
      httpOptions
    );
  }
  public postWithStaticToken(api, data, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // token: serviceType === 2 ? '32855d9d3193f985697f289a0e7cb657' : '9d73076203afeeb232aacadc7c8c8c33'
        token:token
      })
    };
    return this.http.post(
      "https://carter-cors.herokuapp.com/" + environment.baseUrl + api,
      data,
      httpOptions
    );
  }


  public postWithCorporateTokenCargo(api, data, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token:token
      })
    };
    return this.http.post(
      "https://carter-cors.herokuapp.com/" + environment.baseUrl + api,
      data,
      httpOptions
    );
  }

}
