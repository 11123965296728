import { Component, OnInit } from '@angular/core';
import { LuggageGuideComponent } from '../../../../shared/modals/luggage-guide/luggage-guide.component';
import { PickAirportService } from '../../../../core/services/pick-airport/pick-airport.service';
import { apis } from '../../../../config/apis';
import { CrudService } from '../../../../core/services/crud.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-rate-card',
  templateUrl: './rate-card.component.html',
  styleUrls: ['./rate-card.component.scss']
})
export class RateCardComponent implements OnInit {
  airportdata: any;
  airportnames: any;
  city: any;
  luggageArray: any;
  constructor(private dialog: MatDialog, private pickAirport: PickAirportService,private crud: CrudService,private spinnerService: Ng4LoadingSpinnerService,) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.getAirport();
  }

  receiveMessage($event) {
    this.luggageArray = $event
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LuggageGuideComponent, {
      width: '800px', panelClass: 'custom-dialog-container'
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  // Loading airports
  airportId:any;
  data:any;
  getAirport() {
    this.spinnerService.show();
    this.pickAirport.getAirport(apis.GET_CITY).subscribe(data => {
      this.airportdata = data;
      this.airportnames = this.airportdata.response.airport;
      console.log("this.airportnames",this.airportnames);
      this.city = this.airportdata.response.region;
      console.log("city",this.city);
      for(let i = 0; i<this.city.length; i++){
        if(this.city[i].region_id == 1){
          this.regionId = this.city[i].region_id;
          console.log("this.regionId",this.regionId);
          this.selectedCity = this.city[i].region_name;
          this.getLuggageDetails(this.city[i].region_id) 
          this.spinnerService.hide();
        }
      }
    });
  }

  avtiveTab:any = 'local';
  setFlag(value){
    this.avtiveTab = value;
  }

  showDropdownList:boolean = false;
  showHideDropDownList(){
    this.showDropdownList = !this.showDropdownList;
  }

  selectedCity:any;
  regionId:any;
  setCity(city,id){
    this.selectedCity = city;
    this.getLuggageDetails(id);
    this.regionId = id;
    console.log("this.regionId",this.regionId);
    this.showDropdownList = false;
  }


  getLuggageDetails(regionId){
    this.spinnerService.show();
    let airportId;
    for(let i =0; i<this.airportnames.length;i++){
      if(this.airportnames[i].fk_tbl_city_of_operation_region_id == regionId){
        console.log("here")
        airportId = this.airportnames[i].airport_name_id
        const obj = {
          'airport_id': airportId
        }
        this.crud.post(apis.GET_LUGGAGE_TYPE,obj).subscribe(
          res => {
            console.log("baggage-cost",res["luggage_types"]);
            this.data =  res["luggage_types"]; 
            this.spinnerService.hide();
          }
        )
        break;
      }
    }
  }



}
